import Head from 'next/head'
import * as Sentry from '@sentry/browser'
import Image from 'next/image'
import { useSnackbar } from 'notistack'
import styles from '../styles/Home.module.css'
import { styled } from '@mui/material/styles'
import Plyr from 'plyr-react'
import ConnectButton from '../src/components/ConnectButton'
import {
  useEthers,
  useEtherBalance,
  useContractCall,
  useContractFunction,
} from '@usedapp/core'
import { formatEther } from '@ethersproject/units'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { useEffect, useState, useRef, useContext } from 'react'
import useMetaMaskHook from '../src/hooks/useMetaMaskHook'
import { ContractContext } from '../src/context/ContractContext'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import CameraIcon from '@mui/icons-material/PhotoCamera'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Header from '../src/components/Header'
import Slider from '@mui/material/Slider'

const simpleContractAddress = process.env.CONTRACT_ADDR

const videoSrc = {
  type: 'video',
  sources: [
    {
      src: 'kFfb_dmOAik',
      provider: 'youtube',
    },
  ],
}
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const items = [
  {
    image: '1.webp',
    title: 'Rare',
    description: (
      <>
        <div>Direct trade for tribe character + gas fee.</div>
        <div>Allocation of ecosystem coin.</div>
        <div>Long term basic holder rewards.</div>
      </>
    ),
  },

  {
    image: '2.webp',
    title: 'Ultra Rare',
    description: (
      <>
        <div>Direct trade for tribe character + gas fee.</div>
        <div>Allocation of ecosystem coin.</div>
        <div>
          Mid-tier rewards that include apparel, trait and asset drops, and
          priority mint privileges.
        </div>
      </>
    ),
  },
  {
    image: '3.webp',
    title: 'Legendary',
    description: (
      <>
        <div>Direct trade for tribe character + gas fee.</div>
        <div>Allocation of ecosystem coin.</div>
        <div>
          These are the highest tier rewards that also include character drops,
          VIP access, and physical rewards.
        </div>
      </>
    ),
  },
]
export default function Home() {
  const metamask = useMetaMaskHook()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [nftCount, setNftCount] = useState(1)
  const [result, setResult] = useState('')
  const { ownerAddress, contract, abiInterface, setContractSigner } =
    useContext(ContractContext)
  const { account, chainId, library, ...rest } = useEthers()

  const [population] =
    useContractCall(
      abiInterface &&
        ownerAddress && {
          abi: abiInterface,
          address: ownerAddress,
          method: 'population',
        }
    ) ?? []

  const [populationCap] =
    useContractCall(
      abiInterface &&
        ownerAddress && {
          abi: abiInterface,
          address: ownerAddress,
          method: 'populationCap',
        }
    ) ?? []

  const [mintingPrice] =
    useContractCall(
      abiInterface &&
        ownerAddress && {
          abi: abiInterface,
          address: ownerAddress,
          method: 'mintingPrice',
        }
    ) ?? []

  const [owner] =
    useContractCall(
      abiInterface &&
        ownerAddress && {
          abi: abiInterface,
          address: ownerAddress,
          method: 'owner',
        }
    ) ?? []

  const mintNft = async () => {
    const signable = contract.connect(library.getSigner())
    try {
      const res = await signable.mintBatch(nftCount, {
        value: mintingPrice.mul(nftCount),
      })
      const receipt = await res.wait(1)
      if (receipt.status) {
        enqueueSnackbar('You have successfully minted!', {
          autoHideDuration: 5000,
          persist: false,
        })
      }
      Sentry.addBreadcrumb({
        category: 'mint',
        message: `Authenticated user ${account} just minted ${nftCount} at ${formatEther(
          mintingPrice.mul(nftCount)
        )}}`,
        level: Sentry.Severity.Info,
      })
    } catch (e) {
      const message = e?.message || e?.error?.message || 'Unknown Error'
      const code = e?.code || e?.error?.code || 'Unkown Error Code'
      Sentry.addBreadcrumb({
        category: 'mint',
        message: `Authenticated user ${account} unable to mint ${nftCount} at ${formatEther(
          mintingPrice.mul(nftCount)
        )}} because of ${code}:${message}`,
        level: Sentry.Severity.Error,
      })
      enqueueSnackbar(message, {
        autoHideDuration: 5000,
        persist: false,
        variant: 'error',
      })
    }
  }

  return (
    <div>
      <Head>
        <title>Mint Your Lootbox</title>
        <meta name="description" content="Mint Your Lootbox" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header />
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          {account ? (
            <>
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
              >
                Total Minted
              </Typography>
              <Box m={1} display="flex" justifyContent="center">
                <Typography variant="h4" color="white">
                  {population?.toNumber()}&nbsp;
                </Typography>

                <Typography variant="h4" color="white">
                  /&nbsp;{populationCap?.toNumber()}
                </Typography>
              </Box>
              <Typography
                variant="h5"
                align="center"
                color="text.secondary"
                my={5}
              >
                Price: {mintingPrice && formatEther(mintingPrice.mul(nftCount))}{' '}
                + Gas.
              </Typography>

              <Stack>
                <Slider
                  onChange={(event) => setNftCount(event.target.value)}
                  step={1}
                  marks
                  min={1}
                  max={3}
                  value={nftCount}
                  valueLabelDisplay="on"
                />
              </Stack>
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
                justifyContent="center"
              >
                <Button color="warning" variant="contained" onClick={mintNft}>
                  Mint
                </Button>
              </Stack>
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ConnectButton />
            </Box>
          )}
        </Container>
        <Container sx={{ py: 8 }} maxWidth="md">
          <Grid container spacing={4}>
            {items.map((item) => (
              <Grid item key={item.title} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={`./images/${item.image}`}
                    // style={{height: "100%"}}
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {item.title}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        height: 200,
                      }}
                    >
                      {item.description}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            src="/images/logo.png"
            alt="NKD Project"
            height="50"
            width="100"
          />
        </div>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Don&apos;t miss our metaverse scavenger hunt on January 22!
        </Typography>
        {/* <Copyright /> */}
      </Box>
    </div>
  )
}
