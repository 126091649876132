import { useEffect, useState } from 'react'

const useMetaMaskHook = () => {
  const [metamask, setMetaMask] = useState(true)
  useEffect(() => {
    if (typeof window.ethereum === 'undefined') {
      //console.error('Ethereum not found, make sure you install metamask or if on mobile use metamask browser');
      setMetaMask(false)
      return
    }
    if (!window.ethereum.isMetaMask) {
      //console.error("I can't find metamask, please install the extension or if mobile use the metamask browser");
      setMetaMask(false)
      return
    }
  }, [])

  return metamask
}

export default useMetaMaskHook
