import React from 'react'
import AppBar from '@mui/material/AppBar'
import Image from 'next/image'
import CameraIcon from '@mui/icons-material/PhotoCamera'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Avatar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useEthers, useEtherBalance } from '@usedapp/core'
import Account from './Account'
import ConnectButton from './ConnectButton'
import useMediaQuery from '@mui/material/useMediaQuery';

const Header = () => {
  const { account } = useEthers()
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <AppBar position="relative">
      <Toolbar>
        <div
          style={{
            display: 'flex',
            justifyContent: matches ? 'space-between' : 'center',
            width: '100%',
            alignItems: 'center',
          }}
        >
            {matches && (<>  <div style={{ overflow: 'hidden' }}>
            {account ? <Account /> : <ConnectButton />}
          </div></>)}
        
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Image src="/images/logo.png" alt="NKD Project" height="50" width="100"/>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
