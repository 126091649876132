import { useEthers, useEtherBalance } from '@usedapp/core'
import { formatEther } from '@ethersproject/units'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { grey } from '@mui/material/colors'
import Identicon from './Identicon'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'

const Account = () => {
  const { account, deactivate } = useEthers()
  const etherBalance = useEtherBalance(account)
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: grey[700],
        borderRadius: 1,
        px: 3,
        m: 1,
      }}
    >
      <Box mr={3} display="flex" alignItems="center">
        <AccountBalanceWalletIcon />
        <Typography sx={{ color: 'white', fontSize: 16 }} mr="3">
          {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)} ETH
        </Typography>
      </Box>
      <Button
        onClick={deactivate}
        variant="contained"
        sx={{
          background: grey[800],
          border: '1px solid transparent',
          borderRadius: 2,
          m: '1px',
          height: '38px',
        }}
        _hover={{
          border: '1px',
          borderStyle: 'solid',
          borderColor: 'blue.400',
          backgroundColor: 'gray.700',
        }}
      >
        <Typography color="white" mr={0.5}>
          {account &&
            `${account.slice(0, 6)}...${account.slice(
              account.length - 4,
              account.length
            )}`}
        </Typography>
        <Identicon />
      </Button>
    </Box>
  )
}
export default Account
